.singleCertification {
    box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
    width: 390px;
    height: 292px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.9rem 0.2rem;
    margin: 0.1rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.certificationContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.certificationContent > img {
    width: 90%;
    height: 87%;
    border-width: 1px;
    border-color: black;
    border-style: solid;
}

.certificationContent > h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-size: 1.225rem;
    font-weight: 600;
    line-height: 110%;
    text-align: center;
}

.singleCertification .certification--desc{
    position: absolute;
    margin-top: 8rem;
    font-size: 0.8rem;
    width: 75%;
    height: 90px;
    left: 0;
    top: 0;
    transform: translate(-110%,40%);
    padding: 15px;
    border-radius: 0 20px 20px 0;
    transition: transform 0.9s;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primaryFont);
}
  
.singleCertification:hover .certification--desc{
    transform: translate(-2%,40%);
}